export const blogUtils = {
  methods: {
    tagsColor(tagId) {
      if (tagId % 7 === 6) return 'light-secondary'
      if (tagId % 7 === 5) return 'light-danger'
      if (tagId % 7 === 4) return 'light-primary'
      if (tagId % 7 === 3) return 'light-warning'
      if (tagId % 7 === 2) return 'light-success'
      if (tagId % 7 === 1) return 'light-info'
      return 'light-primary'
    },
  },
}

export const _ = null
