<template>
  <div>
    <!-- sidebar -->
    <div class="blog-sidebar py-2 py-lg-0">
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Search here"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- categories -->
      <div class="blog-categories mt-2">
        <h6 class="section-label mb-1">
          {{ $t('Categories') }}
        </h6>

        <div class="d-flex">
          <div class="d-flex justify-content-start align-items-center mb-2 mr-2">
            <b-button
              size="sm"
              class="mr-75"
              variant="outline-primary"
              to="/my-announcements"
            >
              {{ $t('Promotions I participate') }}
            </b-button>
          </div>
          <div
            v-for="category in categories"
            :key="category.id"
            class="d-flex justify-content-start align-items-center mb-2 mr-2"
          >
            <b-link :to="'/announcements/'+category.id">
              <b-badge
                :variant="tagsColor(category.id)"
                class="mr-75"
              >
                {{ category.title }}
              </b-badge>
            </b-link>
          </div>
        </div>
      </div>
      <!--/ categories -->
    </div>
    <!--/ sidebar -->

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col
        v-for="post in blog"
        :key="post.id + post.title"
        md="6"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: post.isPromotion ? 'promotion-single' : 'announcement-single', params: { id: post.id } }">
            <b-img
              :src="post.img"
              :alt="post.title"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: post.isPromotion ? 'promotion-single' : 'announcement-single', params: { id: post.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ post.title }}
              </b-link>
            </b-card-title>
            <div class="media">
              <small class="text-muted">{{ post.creationDate }}</small>
            </div>
            <div class="my-1 py-25">
              <b-link
                v-for="category in post.categories"
                :key="category.id"
                :to="'/announcements/'+category.id"
              >
                <b-badge
                  :variant="tagsColor(category.id)"
                  pill
                  class="mr-75"
                >
                  {{ category.title }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text
              class="blog-content-truncate"
              v-html="post.description"
            />
            <hr/>
            <div class="d-flex justify-content-end align-items-center">
              <b-link
                :to="{ name: post.isPromotion ? 'promotion-single' : 'announcement-single', params: { id: post.id } }"
                class="font-weight-bold"
              >
                {{ $t('Read More') }}
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="blog.length > 20"
        cols="12"
      >
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="blog.length"
            align="center"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->
  </div>
</template>

<script>
import axios from '@/libs/axios'
import {
  BRow, BCol, BButton, BCard, BFormInput, BCardText, BCardTitle, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'
import { blogUtils } from '@core/mixins/ui/blog'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  mixins: [blogUtils],
  data() {
    return {
      search_query: '',
      debounce: null,
      announcements: [],
      promotions: [],
      categories: [],
      currentPage: 1,
    }
  },
  computed: {
    blog() {
      const blog = [...this.announcements, ...this.promotions]

      blog.sort((a, b) => {
        if (a.creationDate === null) return 1
        if (b.creationDate === null) return -1
        if (a.creationDate === b.creationDate) return 0
        return a.creationDate > b.creationDate ? -1 : 1
      })

      return blog
    },

    categoryID() {
      return this.$route.params.categoryID
    },

    personalOnly() {
      return this.$route.name === 'my-announcements'
    },
  },
  created() {
    this.fetchAnnouncements()
    this.fetchPromotions()

    this.$watch('search_query', () => {
      if (this.debounce) return

      this.debounce = setTimeout(() => {
        this.fetchAnnouncements()
        this.fetchPromotions()

        this.debounce = null
      }, 300)
    })

    this.$watch('categoryID', () => {
      this.fetchAnnouncements()
      this.fetchPromotions()
    })

    this.$watch('personalOnly', () => {
      this.fetchAnnouncements()
      this.fetchPromotions()
    })

    this.$store.dispatch('app-common/fetchPostCategories')
      .then(response => {
        this.categories = response.data
      })
  },
  methods: {
    fetchAnnouncements() {
      if (this.personalOnly) {
        this.announcements = []
      } else {
        axios.get('announcements/search', {
          params: {
            q: this.search_query,
            category: this.categoryID,
            public: true,
          },
        }).then(res => {
          this.announcements = res.data.content.map(e => {
            e.isPromotion = false
            return e
          })
        })
      }
    },

    fetchPromotions() {
      axios.get('promotions/search', {
        params: {
          q: this.search_query,
          category: this.categoryID,
          personal: this.personalOnly,
          public: true,
        },
      }).then(res => {
        this.promotions = res.data.content.map(e => {
          e.isPromotion = true
          return e
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-blog.scss";
</style>
